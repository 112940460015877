/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { AppMenu } from './AppMenu';
import { AppSideMenu } from './AppSideMenu';
import { AppFooter } from './AppFooter';
export class AppPanel extends Component {
    static displayName = AppPanel.name;

    render() {
        return (
            <div class="wrapper">
                <AppMenu />
                <AppSideMenu />
                    {this.props.children}
                <AppFooter />
            </div>
        );
    }
}
