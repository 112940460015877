import React, { Component } from 'react';
import  AppList  from '../core/AppList'; // Assuming DataTableExample component is in the same directory


export class Dashboard extends Component {
    static displayName = Dashboard.name;

    render() {
        return (
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Dashboard</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/home">Home</a></li>
                                    <li className="breadcrumb-item active">Dashboard v1</li>
                                </ol>
                            </div>
                        </div>
                        <div style={{ maxWidth: "100%" }}>
                            <AppList />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

