import { Dashboard }  from './Dashboard';

const AppRoutes = [
    {
        index: true,
        path: '/dashboard',
        element: <Dashboard />
    }
];

export default AppRoutes;
