import React, { Component } from 'react';
export class AppMenu extends Component {
  static displayName = AppMenu.name;

  render() {
      return (
          <nav class="main-header navbar navbar-expand navbar-white navbar-light">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
              </li>
              <li class="nav-item d-none d-sm-inline-block">
                <a href="/list" class="nav-link">List</a>
              </li>
            </ul>
          </nav>
    );
  }
}
