import React, { Component } from 'react';
export class AppFooter extends Component {
    static displayName = AppFooter.name;

  render() {
      return (
          <footer class="main-footer">
              <strong>Copyright &copy; 2024 <a href="http://taracode.com/">TaraCode Inc</a>.</strong>
              All rights reserved.
              <div class="float-right d-none d-sm-inline-block">
                  <b>Version</b> 1.0.0
              </div>
          </footer>
    );
  }
}
