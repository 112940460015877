/* eslint-disable react/style-prop-object */
import React, { Component } from 'react';
export class AppSideMenu extends Component {
    static displayName = AppSideMenu.name;

  render() {
      return (
          <aside class="main-sidebar sidebar-dark-primary elevation-4">
              <a href="/index" class="brand-link">
                  <img src="/images/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" />
                  <span class="brand-text font-weight-light">TaraCode AIMS</span>
              </a>
          </aside>
    );
  }
}
