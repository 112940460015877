import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';

import WebRoutes from './components/website/WebRoutes';
import { Layout } from './components/website/Layout';

import { Login } from "./components/core/Login";

import AppRoutes from './components/app/AppRoutes';
import { AppPanel } from './components/core/AppPanel';

export default class App extends Component {
  static displayName = App.name;
  render() {
      return (
        <Routes>
            {WebRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={<Layout>{element}</Layout>} />;
            })}

            <Route path="/login" element={<Login />}></Route>

            {AppRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={<AppPanel>{element}</AppPanel>} />;
            })}
        </Routes>
    );
  }
}
