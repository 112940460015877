import React, { Component } from 'react';
import axiosInstance from './axiosConfig';

export class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            error: ''
        };
    }

    handleInputChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value,
            error: '' // Clear previous error messages on input change
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const { username, password } = this.state;

        if (!username || !password) {
            this.setState({ error: 'Username and password are required' });
            return;
        }

        axiosInstance.post('/api/Login', { Username: username, Password: password })
            .then((response) => {
                console.log('Login successful', response.data);
                // Redirect user to the dashboard page
                window.location.href = '/dashboard';
            })
            .catch((error) => {
                console.error('Login failed', error.response);
                const errorMsg = error.response?.data?.message || 'Invalid username or password' + username;
                this.setState({ error: errorMsg });
            });
    };

    render() {
        const { username, password, error } = this.state;

        return (
            <div className="login-page">
                <div className="login-box">
                    <div className="login-logo">
                        <b>TARACODE</b>IAMS
                    </div>
                    <div className="card">
                        <div className="card-body login-card-body">
                            {error && (
                                <p className="login-box-msg" style={{ color: 'red' }}>
                                    {error}
                                </p>
                            )}
                            <form onSubmit={this.handleSubmit}>
                                <div className="input-group mb-3">
                                    <input
                                        className="form-control"
                                        placeholder="Username"
                                        type="text"
                                        name="username"
                                        value={username}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-user"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input
                                        className="form-control"
                                        placeholder="Password"
                                        type="password"
                                        name="password"
                                        value={password}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="social-auth-links text-center mb-3">
                                    <button type="submit" className="btn btn-primary btn-block">
                                        Sign In
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
