import axios from 'axios';

// Create an instance of axios with default configuration
const axiosInstance = axios.create({
    baseURL: 'http://localhost:8081', // Set your base URL here
    timeout: 10000, // Set a timeout if needed
    headers: {
        'Content-Type': 'application/json',
        // Add other default headers if needed
    },
});

export default axiosInstance;
